<template>
  <div class="pay-wx">
    <div class="pay-wx-title">
      <p class="pay-wx-title-text">微信支付列表</p>
      <div class="pay-wx-title-container">
        <el-button size="mini" type="primary" plain @click="$router.push({name: 'collection.pay.wx.config'})">新增微信支付</el-button>
      </div>

      <div class="pay-wx-title-filter">
        <p>过滤条件:</p>
        <el-input v-model="pagination.name" placeholder="请输入微信支付名称" @change="load" size="mini"/>
      </div>
    </div>

    <div class="pay-wx-contain">
      <el-table :data="pay"
                max-height="420px">
        <empty slot="empty"></empty>
        <el-table-column label="支付编号" align="center" prop="uuid" width="240px"></el-table-column>
        <el-table-column label="支付名称" align="center" prop="name"></el-table-column>
        <el-table-column label="商户ID" align="center" prop="mchId"></el-table-column>
        <el-table-column label="申请状态" align="center" prop="remark"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope" >
           <div v-if="scope.row.status===5">
             <span class="edit" @click="handleDeviceShow(scope.row)">绑定设备</span>
             <span class="edit" @click="handleEditPay(scope.row)" >测试支付</span>
             <span class="delete" @click="handleDelete(scope.row)">删除</span>
           </div>
            <div :class="scope.row.status===0? 'disabled':''" v-else><span :disabled="scope.row.status===0" class="edit" @click="handleEditPay(scope.row)" >编辑</span></div>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>



    <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">微信支付列表</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindWechat">关联微信支付</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.name" placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices"></devices>
    </c-dialog>
  </div>

</template>

<script>

  import {payWxCreate, payWxList, payWxUnion, payWxUpdate, payWxDelete} from "@/api/collection/pay";
  import {checkObj, fillObj} from "@/util/checkLogin";
  import CDialog from '@/components/Dialog'
  import Devices from '@/components/Device'
  import {payBindDevice} from "@/api/collection/pay";

  export default {
    name: '',
    components: {
      CDialog,
      Devices
    },
    mounted: function () {
      this.load()
    },
    data: function () {
      return {
        pay: [],
        createVisible: false,
        editVisible: false,
        showInfo: false,
        bindVisible: false,
        cert: {
          secret: '',
          key: '',
          pem: '',
        },
        wxForm: {
          name: '',
          appId: '',
          mchId: '',
          mchSecret: '',
          certKey: '',
          certPem: '',
        },
        mWxForm: {
          uuid: '',
          name: '',
          appId: '',
          mchId: '',
          mchSecret: '',
          certKey: '',
          certPem: '',
        },
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
          name: ''
        },
        bind: {
          uuid: '',
          name: ''
        }
      }
    },
    methods: {
      load() {
        payWxUnion(this.pagination).then(res => {
          this.pay = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },

      showSecret(data) {
        this.cert = {
          secret: data.mchSecret,
          key: data.certKey,
          pem: data.certPem,
        }
        this.showInfo = true
      },

      handleDeviceShow(data) {
        this.bindVisible = true
        this.bind.uuid = data.uuid
        this.$nextTick(function () {
          this.$refs.devices.loadDevices(this.bind.name)
        })
      },

      inputChange(val) {
        this.$refs.devices.loadDevices(val)
      },

      handleEditPay(data) {
        fillObj(this.mWxForm, data)
        this.editVisible = true
      },

      handleCreateWx() {
        let result = checkObj(this.wxForm, [
          {value: "name", message: "支付名称"},
          {value: "appId", message: "公众号ID"},
          {value: "mchId", message: "商户号ID"},
          {value: "mchSecret", message: "商户密钥"},
          {value: "certKey", message: "商户证书Key"},
          {value: "certPem", message: "商户证书Pem"},
        ])

        if (!result.status) {
          this.$message.warning(result.message + `不能为空`)
          return
        }
        payWxCreate(this.wxForm).then(res => {
          this.createVisible = false
          this.load()
          this.wxForm = {
            name: '',
            appId: '',
            mchId: '',
            mchSecret: '',
            certKey: '',
            certPem: '',
          }
        })
      },

      handleUpdateWx() {
        let result = checkObj(this.mWxForm, [
          {value: "name", message: "支付名称"},
          {value: "appId", message: "公众号ID"},
          {value: "mchId", message: "商户号ID"},
          {value: "mchSecret", message: "商户密钥"},
          {value: "certKey", message: "商户证书Key"},
          {value: "certPem", message: "商户证书Pem"},
        ])

        if (!result.status) {
          this.$message.warning(result.message + `不能为空`)
          return
        }

        payWxUpdate(this.mWxForm).then(res => {
          this.editVisible = false
          this.handleCurrentChange()
        })
      },
      handleCurrentChange(value) {
        if (value !== undefined) {
          this.pagination.curPage = value
          this.pagination.start = (value - 1) * this.pagination.limit
        }
        payWxList(this.pagination).then(res => {
          this.pay = res.data || []
        })
      },

      handleBindWechat() {
        let deviceUuid = this.$refs.devices.getDevices() || []
        if (deviceUuid.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        payBindDevice({uuid: this.bind.uuid, deviceUuid:deviceUuid, isAli: false}).then(res => {
          this.bindVisible = false
          this.$message.success('绑定成功')
        })
      },
      handleDelete(row) {
        this.$confirm('此操作将删除支付, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          payWxDelete({uuid: row.uuid}).then(() => {
            this.$message.success('删除成功')
            this.load()
          })
        }).catch(() => {

        })
      },
    }
  }
</script>

<style lang="scss">
  .pay-wx {
    &-title {
      display: flex;
      padding: $padding 0;
      align-content: center;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
      }

      &-filter {
        display: flex;
        align-content: center;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }


    }

    .input-title {
      display: flex;
      padding: $padding 0;
      align-content: center;
      z-index: 99;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
        text-align: left;
      }

      &-filter {
        //flex: 1;
        display: flex;
        text-align: right;
        align-content: center;
        margin-right: $padding;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }
    }

    .edit {
      margin-right: $padding;
      color: $btn_color;
      cursor: pointer;
    }

    span:disabled{
      color: #999;
      background-color: #FFF;
      cursor: not-allowed;
    }

    .delete {
      color: $btn_sec_color;
      margin-right: $padding;
      cursor: pointer;
    }

    .look {
      color: $_theme_color;
      cursor: pointer;
    }


    .wx-form {
      padding: $padding;
    }
  }
</style>
